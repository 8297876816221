import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';
import React from 'react';
import { relativeZindexStyle } from '../utils';
import './resourceCards.scss';

interface ResourceCard {
    title: string;
    bodyText: string;
    actionText: string;
    linkUrl: string;
}
interface ResourceCardsProps {
    cards: ResourceCard[];
    moduleClass: string;
}
const ResourceCardComponent = ({
    title,
    bodyText,
    actionText,
    linkUrl,
}: ResourceCard) => (
    <div className="resource-card get-the-most-card g eds-bg-color--ui-1000">
        <a href={linkUrl} className="eds-l-pad-all-4">
            <div className="eds-text-hm bld-text eds-text-color--white flex-auto">
                {title}
            </div>
            <p className="eds-text-bl eds-align--left eds-text-color--white eds-l-pad-top-4">
                {bodyText}
            </p>
            <div className="eds-align--center-vertical get-the-most-cta flex-auto eds-l-pad-top-5 ">
                <p className="cta-text eds-text-bl bold-text eds-text-color--ui-orange">
                    {actionText}
                </p>
                <span>
                    <Icon
                        type={<ArrowRightChunky />}
                        size="small"
                        color="ui-orange"
                    />
                </span>
            </div>
        </a>
    </div>
);

const ResourceCardsModule = ({
    cards,
    moduleClass = '',
}: ResourceCardsProps) => (
    <section className={`mktg-top-p-100 ${moduleClass}`}>
        <div className="marketing-container__resource_card">
            <div className="container-card box" style={relativeZindexStyle}>
                {cards.map((card, idx) => (
                    <ResourceCardComponent key={idx} {...card} />
                ))}
            </div>
        </div>
    </section>
);

export default ResourceCardsModule;
